import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0112caab&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Loading: require('/codebuild/output/src2174269249/src/and-dine/components/Loading.vue').default,NavBar: require('/codebuild/output/src2174269249/src/and-dine/components/NavBar.vue').default,ShoppingCart: require('/codebuild/output/src2174269249/src/and-dine/components/ShoppingCart.vue').default,FooterBar: require('/codebuild/output/src2174269249/src/and-dine/components/FooterBar.vue').default})
